import * as styles from './Filter.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import {
  resetTableFilter,
  setTableFilter,
  tableFilter,
} from '../../state/slices';
import { useDispatch, useSelector } from 'react-redux';

import DropdownIcon from '../../images/dropdown.svg';
import FilterDropdown from './FilterDropdown.js';

const Filter = (props) => {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);
  const { rows, filterOptions, categoryOptions, setFilter } = props;
  const tableState = useSelector(tableFilter);
  const columnNames = ['Type', 'Category'];

  const [selectState, setSelectState] = useState({
    Type: '',
    Category: '',
  });

  const { Type, Category } = selectState;

  const filteredCategoryObj = useMemo(() => {
    const list = rows.map((row) => {
      return { Type: row.values.Type, Category: row.values.Category };
    });

    const shortenedList = list.filter((el, index, self) => {
      return (
        self.findIndex((t) => {
          return t.Type === el.Type && t.Category === el.Category;
        }) === index
      );
    });

    return shortenedList;
  }, [rows]);

  // state organizer
  useEffect(() => {
    const syncSelectStateWithRedux = () => {
      const typeFilter = tableState.find((el) => el.id === 'Type');
      const categoryFilter = tableState.find((el) => el.id === 'Category');
      let newState = {
        Type: typeFilter ? typeFilter.value : '',
        Category: categoryFilter ? categoryFilter.value : '',
      };
      setSelectState(newState);
    };
    syncSelectStateWithRedux();
  }, [tableState]);

  const crossReferenceCategory = (categoryFilter) => {
    const categoryObj = filteredCategoryObj.find(
      (el) => el.Category === categoryFilter
    );
    if (categoryFilter !== '') {
      if (Type !== categoryObj.Type) {
        return [false, categoryObj];
      } else return [true, categoryObj];
    } else return ['reset', categoryObj];
  };

  const changeState = (e, filterName) => {
    if (filterName === 'Type') {
      const newState = {};
      newState[filterName] = e.target.value;
      newState['Category'] = '';
      setSelectState(newState);
      return;
    }

    if (filterName === 'Category') {
      const crossReference = crossReferenceCategory(e.target.value);
      const [isCorrectType, categoryObj] = crossReference;

      if (isCorrectType === 'reset') {
        const newState = { Type: '', Category: '' };
        return setSelectState(newState);
      }
      if (!isCorrectType) {
        const newState = { ...selectState };
        newState[filterName] = e.target.value;
        newState['Type'] = categoryObj.Type;
        return setSelectState(newState);
      }
    }
    const newState = { ...selectState };
    newState[filterName] = e.target.value;
    return setSelectState(newState);
  };

  // button handlers
  const submitFilter = () => {
    const filterToSubmit = [];

    for (let key in selectState) {
      filterToSubmit.push({
        id: key,
        value: selectState[key],
      });
    }
    dispatch(setTableFilter(filterToSubmit));
    setFilter(filterToSubmit);
    setShowFilter(false);
  };

  const resetFilter = () => {
    setSelectState({
      Type: '',
      Category: '',
    });

    dispatch(resetTableFilter());
    setFilter([]);
    setShowFilter(false);
  };

  const openDropdown = [styles.Filter, styles.OpenDropdown].join(' ');

  return (
    <div className={showFilter ? openDropdown : styles.Filter}>
      <span
        onClick={() => {
          setShowFilter(!showFilter);
        }}>
        Filter By
        <div className={styles.Dropdown}>
          <DropdownIcon />
        </div>
      </span>
      <div className={styles.DropdownFilters}>
        {columnNames.map((el, i) => {
          return (
            <FilterDropdown
              key={i}
              value={selectState[el]}
              name={el}
              options={filterOptions}
              allcategories={filteredCategoryObj}
              onchange={(e) => changeState(e, el)}
              typeState={Type}
              categoryState={Category}
            />
          );
        })}
        <button onClick={() => submitFilter()}>Set Filters</button>
        <button onClick={() => resetFilter()}>Clear Filters</button>
      </div>
    </div>
  );
};

export default Filter;
