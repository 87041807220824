// extracted by mini-css-extract-plugin
export var TypeButtons = "ProductTable-module--TypeButtons--xF4k_";
export var TypeButton = "ProductTable-module--TypeButton--MFcPA";
export var TypeButtonWrapper = "ProductTable-module--TypeButtonWrapper--1kJZ3";
export var Divider = "ProductTable-module--Divider--1mBKZ";
export var TableWrapper = "ProductTable-module--TableWrapper--16d2t";
export var Table = "ProductTable-module--Table--NEGVA";
export var Button = "ProductTable-module--Button--1eZzr";
export var TableBody = "ProductTable-module--TableBody--25rju";
export var Options = "ProductTable-module--Options--265jQ";
export var TableFooter = "ProductTable-module--TableFooter--V01Aq";
export var Pagination = "ProductTable-module--Pagination--ijmqI";
export var PageButton = "ProductTable-module--PageButton--svM4L";
export var PageLimit = "ProductTable-module--PageLimit--3leGD";