import * as styles from './Search.module.css';

import React, { useState } from 'react';

import Filter from '../Filter/Filter';
import SearchIcon from '../../images/search.svg';

const Search = (props) => {
  const { globalFilter, onChange, setFilter, filters } = props;

  const [value, setValue] = useState(globalFilter);
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onChange(value);
    }
  };

  return (
    <div className={styles.SearchWrapper}>
      <div className={styles.Search}>
        <div className={styles.SearchBar}>
          <input
            className={styles.Input}
            placeholder='Product Search'
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyPress={(e) => handleKeyPress(e)}></input>
          <div className={styles.Submit} onClick={() => onChange(value)}>
            <SearchIcon />
          </div>
        </div>

        <div className={styles.Options}>
          <Filter
            rows={props.rows}
            setFilter={setFilter}
            filters={filters}
            filterOptions={props.filterOptions}
            categoryOptions={props.categoryOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default Search;
