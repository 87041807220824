import React, { useEffect, useState } from 'react';

import { FilterRow } from './Filter.module.css';

const FilterDropdown = (props) => {
  const { name, options, onchange, value, allcategories, typeState } = props;

  const [categoryOptions, setCategoryOptionsState] = useState([]);

  const filteredCategories = allcategories.map((el) => {
    if (typeState === '' || typeState === undefined) {
      return el.Category;
    }
    if (el.Type === typeState) {
      return el.Category;
    }
  });

  useEffect(() => {
    setCategoryOptionsState(filteredCategories);
  }, []);

  const categoryDropdowns = allcategories.map((el, i) => {
    if (typeState !== '' && el.Type === typeState) {
      return (
        <option key={i} value={el.Category}>
          {el.Category}
        </option>
      );
    } else if (typeState === '') {
      return (
        <option key={i} value={el.Category}>
          {el.Category}
        </option>
      );
    }
  });

  return (
    <div className={FilterRow}>
      <div>{name}</div>
      <select value={value} onChange={onchange}>
        <option value=''>All</option>
        {name === 'Type'
          ? options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })
          : categoryDropdowns}
      </select>
    </div>
  );
};

export default FilterDropdown;
