// Italy is mad because I stole all their spaghetti to make this and all subsequent components. -sung woo

import * as styles from './ProductTable.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import {
  setQuoteTrue,
  setTableFilter,
  showPopupQuote,
  tableFilter,
} from '../../state/slices';
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';

import Loading from '../../components/UI/Loading/Loading';
import PopupQuote from '../../components/Forms/PopupQuote';
import Search from '../../components/Search/Search';
import SortDown from '../../images/dropdown.svg';
import SortUp from '../../images/dropdown-reverse.svg';

const ProductTable = (props) => {
  const dispatch = useDispatch();
  const [productData, setProductData] = useState([]);
  const [selectedSku, setSelectedSku] = useState('');
  const showPopupForm = useSelector(showPopupQuote);
  const tableState = useSelector(tableFilter);

  const manufacturer = props.manufacturer.toLowerCase();
  console.log(manufacturer);

  useEffect(() => {
    const url = `https://h1trleotw3.execute-api.us-east-1.amazonaws.com/`;
    const getData = async () => {
      // const body = JSON.stringify({ manufacturer: `${manufacturer}` });
      const response = await fetch(url);
      const data = await response.json();

      let product_items = [];


      for (let i in data){

        let product_item = {};
        for (let item of data[i].match(/<(.*?)>(.*?)<\/(.*?)>/g)){
        let key = item.substring(1,item.search(">"));
          let val = item.substring(item.search(">")+1,item.search("</"));
          product_item[key]=val;
        };

        const product_item_template = {
          "id": 2226,
          "Name": product_item.desc,
          "Price": parseInt(product_item.amt),
          "Stock": 5,
          "Manufacturer": product_item.cat.toLowerCase().includes("cisco")?"Cisco":product_item.cat.toLowerCase().includes("dell")?"Dell":product_item.cat.toLowerCase().includes("hpe")?"HPE":"",
          "SKU": product_item.sku,
          "Category": product_item.st1,
          "Weight": null,
          "AlternatePartNumber": product_item.alt=='null'?'':product_item.alt,
          "published_at": "2022-03-23T13:57:50.561Z",
          "created_at": "2022-03-23T13:57:50.919Z",
          "updated_at": "2022-03-23T13:57:51.279Z",
          "Category1": null,
          "Category2": null,
          "Category3": null,
          "Type": product_item.wg1,
          "Source": null,
          "Image": null
      }


      if(product_item.cat.toLowerCase().includes(manufacturer)){
        product_items.push(product_item_template);
      }


    }


      setProductData(product_items);

      return;
    };

    getData();
  }, [manufacturer]);

  const data = useMemo(() => {
    return productData;
  }, [productData]);

  const toCurrency = (str) => {
    let number = parseFloat(str);
    return number.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  };

  const columns = useMemo(
    () => [
      { Header: 'SKU', accessor: 'SKU' },
      { Header: 'Alternate Part Number', accessor: 'AlternatePartNumber' },
      { Header: 'Name', accessor: 'Name' },
      { Header: 'Type', accessor: 'Type' },
      { Header: 'Category', accessor: 'Category' },
      {
        Header: 'Price',
        accessor: 'Price',
        minWidth: '60px',
        Cell: (props) => <>{!isNaN(props.value)?toCurrency(props.value):'CALL'}</>,
      },
    ],
    []
  );

  const defaultSort = useMemo(() => [{ id: 'Type', desc: false }], []);

  const table = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        sortBy: defaultSort,
        globalFilter: '',
        filters: [],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    setFilter,
    setAllFilters,
    setSortBy,
    sortTypes,
    state: { pageSize, pageIndex, globalFilter, filters, sortBy },
  } = table;

  const filterOptions = useMemo(() => {
    const options = new Set();
    if (productData.length !== 0) {
      productData.forEach((row) => {
        options.add(row['Type']);
      });

      return [...options.values()];
    }
    return [];
  }, [productData]);

  const categoryOptions = useMemo(() => {
    const options = new Set();
    if (productData.length !== 0) {
      productData.forEach((row) => {
        options.add(row['Category']);
      });
    }

    return [...options.values()];
  }, [productData]);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const handleQuoteRequest = (sku) => {
    setSelectedSku(sku);
    dispatch(setQuoteTrue());
  };

  const handleFilterChange = (filterItem) => {
    const filter = { id: 'Type', value: `${filterItem}` };
    dispatch(setTableFilter([filter]));
    setAllFilters([filter]);
  };

  return (
    <>
      {showPopupForm ? <PopupQuote sku={selectedSku} /> : null}
      <div className={styles.TypeButtons}>
        {filterOptions.map((el, i) => {
          return (
            <div key={i} className={styles.TypeButtonWrapper}>
              <div
                className={styles.TypeButton}
                onClick={() => handleFilterChange(el)}>
                {el}
              </div>
              {i !== filterOptions.length - 1 ? (
                <div className={styles.Divider}>|</div>
              ) : null}
            </div>
          );
        })}
      </div>

      <Search
        filters={filters}
        globalFilter={globalFilter}
        onChange={onChange}
        rows={preGlobalFilteredRows}
        filterOptions={filterOptions}
        categoryOptions={categoryOptions}
        setFilter={setAllFilters}
        sort={sortBy}
        setSortBy={setSortBy}
        sortTypes={sortTypes}
      />
      <div className={styles.TableWrapper}>
        {productData.length !== 0 ? (
          <table {...getTableProps()} className={styles.Table}>
            <thead>
              {headerGroups.map((headerGroup) => {
                return (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}>
                          {column.render('Header')}

                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <SortDown />
                            ) : (
                              <SortUp />
                            )
                          ) : (
                            ''
                          )}
                        </th>
                      );
                    })}
                    <td />
                  </tr>
                );
              })}
            </thead>

            <tbody {...getTableBodyProps()} className={styles.TableBody}>
              {page.map((row, i) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                    <td className={styles.Button}>
                    <Link to={`/product/${encodeURIComponent(row.original.SKU)}`} >
                        <button>
                          VIEW PRODUCT
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <Loading />
        )}

        <div className={styles.TableFooter}>
          <div></div>
          <div className={styles.Pagination}>
            <span className={styles.PageButton} onClick={() => gotoPage(0)}>
              {'|<<'}
            </span>

            <span className={styles.PageButton} onClick={() => previousPage(0)}>
              {'<'}
            </span>

            <span className={styles.PageButton} onClick={() => nextPage()}>
              {'>'}
            </span>

            <span
              className={styles.PageButton}
              onClick={() => gotoPage(pageCount - 1)}>
              {'>>|'}
            </span>
          </div>
          <div className={styles.PageLimit}>
            <label htmlFor='page-size'>Products per page</label>
            <select
              name='page-size'
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}>
              <option value='25'>25</option>

              <option value='50'>50</option>

              <option value='100'>100</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductTable;
