import Layout from '../containers/layout';
import MFRTeam from '../components/MFRTeam/MFRTeam';
import ProductTable from '../containers/ProductTable/ProductTable';
import React from 'react';

const Shop = ({ location, pageContext }) => {
  const seoData = {
    MetaTitle: `${pageContext.manufacturer} Products`,
    MetaDescription: `Shop for ${pageContext.manufacturer} enterprise products at V3 Distribution!`,
    Keywords: `V3 Distribution, shop, products`,
  };

  return (
    <Layout seoData={seoData} location={location} noPadding>
      <h1 style={{ marginTop: '2rem' }}>{pageContext.manufacturer} Products</h1>
      <ProductTable
        location={location}
        manufacturer={pageContext.manufacturer}
      />
      <MFRTeam team={pageContext.team} />
    </Layout>
  );
};

export default Shop;
